<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>Admin Settings</strong>
        </CCardHeader>
        <div>
          <CForm novalidate @submit.prevent="onSubmit">
            <CCardBody>
              <div
                  class="form-group"
                  :class="{ 'form-group--error': '' }"
                  v-for="(setting, index) in settingsData"
                  :key="index"
              >
                <label class="form__label"
                >{{ setting.title }}
                  <required_span/>
                </label>
                <input
                    class="form-control"
                    v-model="setting.value"

                />
              </div>
              <CRow>
                <CCol md="12">
                  <div class="form-group" :class="{ 'form-group--error': '' }">
                    <ImageCropper @profileImage="getProfile($event)"/>
                  </div>
                </CCol>
              </CRow>
              <div>
                <CButton
                    type="submit"
                    size="sm"
                    color="primary"
                >
                  <CIcon name="cil-check-circle"/>
                  {{ $lang.buttons.general.crud.update }}
                </CButton>
              </div>
            </CCardBody>
          </CForm>
        </div>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  admin_setting,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import required_span from "../../components/layouts/general/required-span";
import {serverBus} from "../../main";
import VueTelInput from "vue-tel-input-fix";
import "vue-tel-input-fix/dist/vue-tel-input.css";
import {Maxlength} from "../../store/maxlength";

Vue.use(VueTelInput);
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
import ImageCropper from "../ImageCropper";

export default {
  name: "AdminSetting",
  mixins: [Mixin],
  components: {
    required_span,
    ImageCropper,
  },
  data() {
    return {

      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        inputOptions: {
          maxlength: Maxlength.admin_profile.mobile,
        },
        validCharactersOnly: true,
      },
      imageURL: "",
      role: "",
      settingsData: [],
      StateOptions: [],
      cityOptions: [],
      disabled: true,
      disabledLocationField: true,
      messageColor: "success",
      FILE: null,
      existImage: false,
      avatarImage: null,
      imageOutput: "",
      saving: false,
      saved: false,
      adminId: "",
      err_msg: "",
      err_password_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: admin_setting,
      admin_setting: {
        imageURL: "",
        imageRemove: "",
      },
      // maxlength: {
      //   name: Maxlength.admin_profile.name,
      //   email: Maxlength.admin_profile.email,
      //   address1: Maxlength.admin_profile.address1,
      //   oldPassword: Maxlength.admin_profile.oldPassword,
      //   newPassword: Maxlength.admin_profile.newPassword,
      //   confirmPassword: Maxlength.admin_profile.confirmPassword,
      // },
    };
  },
  validations: {},
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    self.adminId = localStorage.getItem("user_id");

    this.loadData();


  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {
    getProfile(profile) {
      let self = this;
      self.admin_setting.imageURL = profile;
      if (profile === "") {
        self.admin_setting.imageRemove = 1;
      } else {
        self.admin_setting.imageRemove = 0;
      }
    },
    onSubmit() {
      let self = this;
      const data = {
        'name': 'SIGNATURESOURCE',
        'title': 'SIGNATURESOURCE',
        'value': self.admin_setting.imageURL,
        'id': '100',
      }
      self.settingsData.push(data);
      const postData = {
        values: self.settingsData,
        signatureDeleted: self.admin_setting.imageRemove,
        // signatureSource: self.admin_setting.imageURL,
        web: true
      };

      axios
          .post(this.updateUrlweb(this.module, self.adminId), postData)
          .then((response) => {
            if (response.data.code == 200) {
              self.err_msg = response.data.message;
              self.messageColor = "success",
                  //this.loadData();
                  self.dismissCountDown = 10;
            } else {
              self.messageColor = "danger",
                  self.err_msg = response.data.message;
              self.dismissCountDown = 10;
            }
          })
          .catch(function (error) {
            self.dismissCountDown = 10;
          });


      /*   this.$v.admin_setting.$touch();
         if (this.$v.admin_setting.$invalid) {
           this.submitStatus = "ERROR";
         } else {
           // Update Code
         }
       */

    },


    setAlert(value) {
      this.showAlert = value;
    },
    createTableRow() {
      this.$router.push({name: "Profile Create"});
    },
    rowClicked(item, index) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios.get(this.listUrlApi(this.module)).then((response) => {

        if (response) {
          self.settingsData = response.data.data;
          self.settingsData.forEach((value, index) => {

            if (value.name === 'SIGNATURE') {
              if (value.value !== "") {
                self.imageURL = value.value;
                serverBus.$emit("send_cropper_image_url", self.imageURL);
                self.existImage = true;
              }
            }
          });
        } else {
          self.data = [];
        }
      });
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
